import React, { useState, FC } from 'react'

import { XStack } from 'tamagui'

import {
  Card,
  Stack,
  Text,
  Grid,
  Column,
  HorizontalLine,
  ExternalLink,
  LinkText,
  Breakpoint,
} from '@components'

import { Text as NewText } from '@newComponents/Text'
import { Link, ExternalLink as NewExternalLink } from '@newComponents/Link'

import { IconWrapper } from '@newComponents/Icon/IconWrapper'
import WindowNew from '@newComponents/Icon/icons/WindowNew'

export const HomeScreenFeaturesCard: FC = () => {
  return (
    <Card variant='homeScreen'>
      <Stack gap={10}>
        <Breakpoint display={0}>
          <GuildsAndNetworks />

          <Events />

          <Presentations />
        </Breakpoint>
        <Breakpoint display={[1, 2, 3, 4]}>
          <Grid gap={6}>
            <Column>
              <GuildsAndNetworks />
            </Column>

            <Column>
              <Events />
            </Column>

            <Column>
              <Presentations />
            </Column>
          </Grid>
        </Breakpoint>

        <Stack gap={0} alignItems='center'>
          <HorizontalLine variant='homeScreenFeatures' />
        </Stack>

        <Stack gap={0} alignItems='center'>
          <Text size={4} variant='caption'>
            Not sure about a feature?
          </Text>

          <Text size={4} variant='caption' textAlign='center'>
            <ExternalLink to='mailto:hi@guild.host'>
              <LinkText size={4} variant='caption'>
                Get in touch
              </LinkText>
            </ExternalLink>
            , we'd love to chat with you!
          </Text>
        </Stack>
      </Stack>
    </Card>
  )
}

const GuildsAndNetworks: FC = () => {
  return (
    <Stack gap={4}>
      <Text size={6} variant='homeGuildsHeading'>
        Guilds & Networks
      </Text>

      <Stack gap={2}>
        {[
          <NewText size='$4'>Create a Following</NewText>,
          <NewText size='$4'>Manage your Members</NewText>,
          <NewText size='$4'>3-tier Permissions System</NewText>,
          <NewText size='$4'>Inter-connect with Networks</NewText>,
          <NewText size='$4'>Cross-promote via Networks</NewText>,
          <NewText size='$4'>Accept Donations</NewText>,
          <NewText size='$4'>Notify your Slack</NewText>,
          <FeatureLinkBulletItem
            href='/integrations/bluesky'
            label='Announce on Bluesky'
          />,
          <FeatureLinkBulletItem
            href='https://codesandbox.io/p/sandbox/guild-sdk-embeds-mxcfmh'
            label='Embeddable Widgets'
            external
          />,
          <FeatureLinkBulletItem
            href='/api/next/docs'
            label='API Access'
            external
          />,
          <FeatureLinkBulletItem
            href='/integrations/meetup'
            label='Mirror your Meetup.com events'
          />,
        ].map((bullet) => (
          <Stack gap={2} direction='horizontal' key={bullet}>
            <Text size={4} variant='homeGuildsCheckmark'>
              ✓
            </Text>

            {bullet}
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}

const FeatureLinkBulletItem = ({
  href,
  label,
  external = false,
}: {
  href: string
  label: string
  external?: boolean
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const WrappingComponent = external ? NewExternalLink : Link

  return (
    <WrappingComponent
      href={href}
      display='flex'
      gap='$2'
      alignItems='center'
      flex={1}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
      size='$4'
      animation='medium'
      color='$grey8'
      hoverStyle={{ color: '$darkBlue10', textDecorationLine: 'none' }}
    >
      {label}

      <XStack animation='medium' x={isHovered ? 4 : 0} width='$4' height='$4'>
        <XStack opacity={0.6} position='absolute'>
          <IconWrapper size='$4' color='$grey8'>
            <WindowNew />
          </IconWrapper>
        </XStack>

        <XStack
          animation='medium'
          opacity={isHovered ? 1 : 0}
          position='absolute'
        >
          <IconWrapper size='$4' color='$darkBlue10'>
            <WindowNew />
          </IconWrapper>
        </XStack>
      </XStack>
    </WrappingComponent>
  )
}

const Events: FC = () => {
  return (
    <Stack gap={4}>
      <Text size={6} variant='homeEventsHeading'>
        Events
      </Text>

      <Stack gap={2}>
        {[
          'In-Person Events',
          'Online Events',
          'Hybrid In-Person & Online Events',
          'Optional Waitlist',
          'Guild Events',
          'One-Off Personal Events',
          'Connect Presentations',
          'Embeddable Widgets',
        ].map((bullet) => (
          <Stack gap={2} direction='horizontal' key={bullet}>
            <Text size={4} variant='homeEventsCheckmark'>
              ✓
            </Text>

            <Text size={4}>{bullet}</Text>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}

const Presentations: FC = () => {
  return (
    <Stack gap={4}>
      <Text size={6} variant='homePresentationsHeading'>
        Presentations
      </Text>

      <Stack gap={2}>
        {[
          "Manage your Guild's Content Library",
          'Manage your Personal Content Library',
          'Connect Presentations to Events',
          'Connect Presentations to Guilds',
          'Curate Content from top Presenters',
          'Share Content across Networks',
          'Embeddable Widgets',
        ].map((bullet) => (
          <Stack gap={2} direction='horizontal' key={bullet}>
            <Text size={4} variant='homePresentationsCheckmark'>
              ✓
            </Text>

            <Text size={4}>{bullet}</Text>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}
